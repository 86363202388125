
import './App.css';

import Drinks from './components/Drinks';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import EnrollmentOptions from './components/EnrollmentOptions';
import DispensingPage from './components/DispensingPage';





function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path="/" element ={<Drinks/>}/>
        {/* <Route path ="/home" element={<Page/>}/> */}
        <Route path ="/dispense" element={<DispensingPage/>}/>

      </Routes>
      </BrowserRouter>
     

    </div>
  );
}

export default App;
