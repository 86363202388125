import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import EnrollmentOptions from './EnrollmentOptions';

import EmailEntry from './EmailEntry';
import DispensingPage from './DispensingPage';
import coke from '../assets/coke.svg';
import beer from '../assets/beer.svg';
import logo from '../assets/logo.png';



function Drinks() {
  const [showEnrollmentModal, setShowEnrollmentModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [isEnrolled, setIsEnrolled] = useState(null);
  const [email, setEmail] = useState('');
  const [responseLink, setResponseLink] = useState('');
  const [redirectToDispensingPage, setRedirectToDispensingPage] = useState(false);
  const navigate = useNavigate();
  const lastEnteredEmailRef = useRef('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedDrink, setSelectedDrink] = useState(null);
  const [showButtons, setShowButtons] = useState(true);


  useEffect(() => {
    // Logic to reset state variables and clear data upon component unmount or page refresh
    return () => {
      // Reset state variables
      setShowEnrollmentModal(false);
      setShowEmailModal(false);
      setIsEnrolled(null);
      setEmail('');
      setResponseLink('');
      setErrorMessage('');
    };
  }, []);
const handleBackButtonClick = () => {
    setShowEmailModal(false);
    setShowEnrollmentModal(true);
  };

  const handleCokeClick = () => {
    navigate('/dispense');

  };
  const handleBeerClick = () => {
    setShowEnrollmentModal(true);
    setSelectedDrink('Beer');
    setShowButtons(false);
  };

  const handleCloseEnrollmentModal = () => {
    setShowEnrollmentModal(false);
  };

  const handleCloseEmailModal = () => {
    setShowEmailModal(false);
  };

  const handleEnrollmentOption = (enrollmentStatus) => {
    setIsEnrolled(enrollmentStatus);
    setShowEnrollmentModal(false);
    console.log(enrollmentStatus)
    setShowEmailModal(true);
  };

  const handleSubmitEmail = (enteredEmail) => {
    lastEnteredEmailRef.current = enteredEmail;
    setEmail(enteredEmail);
    setShowEmailModal(false);
    console.log(enteredEmail)
    // Make appropriate API call based on isEnrolled value
    const requestData = {
      securityData: {
        userName: "ev_integ_64260",
        password: "IDmi#260$",
        merchantId: "44571"
      },
      customerData: {
        companyCode: 64260,
        requestType: isEnrolled ? "Verify" : "Enroll",
        email: enteredEmail
      }
    };

    fetch('https://api.idmission.com/v3/customer/generate-identity-link', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    })
      .then(response => response.json())
      .then(data => {
        if (data && data.customerData && data.customerData.kycUrl) {
          setResponseLink(data.customerData.kycUrl);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });

      // handleCloseModal();
    }
    useEffect(() => {
      const handleMessage = (event) => {
        if (event.data && event.data === "COMPLETED") {
          const raw = JSON.stringify({
            "customer_email": lastEnteredEmailRef.current
          });
          console.log("RAW", raw);
          
          // Adding a 2-second delay before making the API call
          setTimeout(() => {
            const requestOptions = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: raw
            };
            fetch("https://oips.ottumn.com/get_id_form_status", requestOptions) 
              .then((response) => response.json())
              .then((result) => {
                console.log(result);
                console.log(isEnrolled);
                if (isEnrolled && result && result['Form_Status'] === "Approved") {
                  navigate('/dispense');
                } else if (!isEnrolled && result && result['Form_Status'] === "Customer Onboarded") {
                  navigate('/dispense');
                } else {
                  // Handle failure response here
                  setErrorMessage('Enrollment/Verification Failed. Please Try Again');
                  setResponseLink('');// Clear responseLink
              
                }
              })
              .catch((error) => {
                console.error(error);
                // Handle error response here
                setErrorMessage('Enrollment/Verification Failed. Please Try Again');
                setResponseLink('');// Clear responseLink 
              })
              .finally(() => {
                setShowEmailModal(true); // Show EmailEntry modal after API call completes
                setEmail('');
              });
          }, 2000); // 2-second delay
        }
      };
      window.addEventListener('message', handleMessage);
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }, [email, isEnrolled]);

  return (
    
<div className='container' style={{textAlign:'center'}}>
  <div><img src={logo} alt="" style={{marginTop:'30px'}} /></div>
  <div className='choose-drinks' style={{marginTop:'30px'}}> 
    <h1 className="mt-3 mb-3" style={{marginTop:'100px'}}>
      {selectedDrink ? `You have selected ${selectedDrink}` : 'Choose your drink'}
    </h1>
  </div>
  {showButtons && (
    <div className="btn-group mb-3" role="group" aria-label="Drinks" style={{gap:"20px", marginTop:'100px'}}>
      <div style={{ display: 'flex', alignItems: 'center', flexDirection:'column', border: '1px solid green', borderRadius: '8px', padding: '10px'}}>
        <a href="#" onClick={handleBeerClick} style={{ textDecoration: 'none', color: 'inherit' }}>
          <img src={beer} alt="Beer" style={{ width: '30px', marginRight: '5px' }} />
          <button type="button" className="btn btn-light" style={{ backgroundColor: 'transparent', border: 'none' }}>
            Beer
          </button>
        </a>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', flexDirection:'column', border: '1px solid green', borderRadius: '8px', padding: '10px'}}>
        <a href="#" onClick={handleCokeClick} style={{ textDecoration: 'none', color: 'inherit' }}>
          <img src={coke} alt="Coke" style={{ width: '30px', marginRight: '5px' }} />
          <button type="button" className="btn btn-light" style={{ backgroundColor: 'transparent', border: 'none' }}>
            Coke
          </button>
        </a>
      </div>
    </div>)}

      <EnrollmentOptions
        showModal={showEnrollmentModal}
        handleCloseModal={handleCloseEnrollmentModal}
        handleEnrollmentOption={handleEnrollmentOption}
      />

      <EmailEntry
        showModal={showEmailModal}
        handleCloseModal={handleCloseEmailModal}
        handleSetEmail={setEmail}
        isEnrolled={isEnrolled}
        handleSubmitEmail={handleSubmitEmail}
        errorMessage={errorMessage}
        handleBackButtonClick={handleBackButtonClick}
      />

      {responseLink && (
        <iframe src={responseLink} title="Response" style={{ width: '100%', height: '600px'}} allow="camera; microphone" scrolling='no'></iframe>
      )}

      {redirectToDispensingPage && <DispensingPage />}
    </div>
  );
}

export default Drinks;
