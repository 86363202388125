import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './DispensingPage.css';
import logo from '../assets/logo.png';

function DispensingPage() {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      // Redirect back to the original page after 5 seconds
      navigate('/');
    }, 5000); // 5000 milliseconds = 5 seconds

    return () => clearTimeout(timer); // Cleanup function to clear the timer on component unmount
  }, [navigate]);

  return (
    <div className="dispensing-page">
      <div><img src={logo} alt="" style={{marginTop:'30px'}} /></div>
      <div className="dispensing-content">
        <h2>Your drink is now dispensing...</h2>
        <div className="loader"></div>
        {/* You can add additional content here */}
      </div>
    </div>
  );
}

export default DispensingPage;
