import React from 'react';
import { Button } from 'react-bootstrap';

const EnrollmentOptions = ({ showModal, handleCloseModal, handleEnrollmentOption }) => {
  const containerStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#f8f9fa',
    borderRadius: '10px',
    border: '1px solid black',
    padding: '30px',
    width: '350px', // Adjust width as needed
    height: '200px', // Adjust height as needed
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  };

  const buttonStyle = {
    marginRight: '10px',
    fontSize: '16px',
    fontWeight: 'bold',
  };

  return (
    <div style={showModal ? containerStyle : { display: 'none' }}>
      <div>
        <h3 style={{ fontSize: '24px' }}>Age Verification</h3>
        <p style={{ fontSize: '16px' }}>You are required to verify your age for this beverage. Are you a previously enrolled customer?</p>
        <Button variant="primary" style={buttonStyle} onClick={() => handleEnrollmentOption(true)}>Yes</Button>
        <Button variant="secondary" style={buttonStyle} onClick={() => handleEnrollmentOption(false)}>No</Button>
      </div>
    </div>
  );
}

export default EnrollmentOptions;
