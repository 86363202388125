import React, { useState, useEffect } from 'react';
import { Button, Form, Alert } from 'react-bootstrap';

const EmailEntry = ({ showModal, handleCloseModal,errorMessage, handleSubmitEmail, handleBackButtonClick }) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleEmailSubmission = () => {
    if (email.trim() === '') {
      setEmailError('Please enter your email');
      return;
    }
    // Additional email validation logic can be added here
    handleSubmitEmail(email);
  };

  useEffect(() => {
    if (showModal) {
      setEmail('');
      setEmailError('');
    }
  }, [showModal]);

  const containerStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#f8f9fa',
    border: '1px solid black',
    borderRadius: '10px',
    padding: '20px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    width: '350px',
    height: '200px',
  };

  const buttonStyle = {
    marginRight: '10px',
    fontSize: '16px',
    fontWeight: 'bold',
  };

  const inputStyle = {
    border: emailError ? '1px solid black' : '1px solid #ced4da',
    borderRadius: '4px',
    padding: '6px 12px',
    width: '100%',
    boxSizing: 'border-box',
    position: 'relative',
  };

  const errorMessageStyle = {
    position: 'absolute',
    top: '100%',
    left: '0',
    marginTop: '4px', // Add some space between input field and error message
    fontSize: '12px',
    color: 'black',
  };


  return (
    <div style={showModal ? containerStyle : { display: 'none' }}>
      <div>
        <h3 style={{ fontSize: '24px' }}>Enter Email</h3>
        <div style={{ position: 'relative' }}>
          <Form.Control
            type="email" // Use type="email" for email validation
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={inputStyle}
          />
          {emailError && (
            <span style={errorMessageStyle}>{emailError}</span>
          )}
        </div>
        <div style={{ marginTop: '30px' }}>
          <Button variant="primary" style={buttonStyle} onClick={handleEmailSubmission}>Submit</Button>
          <Button variant="secondary" style={buttonStyle} onClick={handleBackButtonClick}>Back</Button>
        </div>
        <div style={{ marginTop: '50px', fontSize: '12px' }}>
          {errorMessage && (
            <Alert variant="danger">{errorMessage}</Alert>
          )}
        </div>
      </div>
    </div>
  )};

export default EmailEntry;